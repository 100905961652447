import React from 'react';
import { styled } from 'styled-components';
import APPLICATION_CONSTANTS from '../constants/strings';
import { Link } from 'react-router-dom';

const CTA = styled(Link)`
    font-family: "neusa-next-std-compact", sans-serif;
    font-weight: 700;
    font-size: 1.6rem;
    border-radius: 48px;
    padding: 1.2rem 4rem;
    background-color: transparent;
    border: ${props => `2px solid ${props.color}`};
    color: ${props => props.color};
    cursor: pointer;
    letter-spacing: .1rem;
    text-decoration: none;

    &:hover {
        background-color: ${props => props.color};
        color: ${props => props.color === "white" ? 'var(--oncourse-blue)' : 'white'};
    }
    a {
        color: ${props => props.color};
        text-decoration: none;
    }
`;

const Button = ({ color, path, string }) => {
    if (!path) {
        switch(string) {
            case(APPLICATION_CONSTANTS.CONTACT_US):
                if ( window.location.href.includes('/partners') ) {
                    path = 'mailto:partnerships@oncoursehome.com';
                }
                if ( window.location.href.includes('/service-providers')) {
                    path = 'mailto:ohsrecruitment@oncoursehome.com'
                }
                else {
                    path = 'mailto:customersupport@oncoursehome.com';
                }
                break;
            case(APPLICATION_CONSTANTS.LEARN_MORE):
                path = 'https://hvac.yourhomesolutions.com/';
                break;
            case(APPLICATION_CONSTANTS.JOB_OPENINGS):
                path = 'https://recruitingbypaycor.com/career/CareerHome.action?clientId=8a7883d0821e1a6301821e55cede0000';
                break;
            default:
                path = '/';
                break;
        };
    };

    const handleScroll = () => {
        if (path?.includes('#')) {
            const yOffset = -100; 
            const element = document.getElementById(path.split("#")[1]);
            const y = element?.getBoundingClientRect().top + window.scrollY + yOffset;
            return window.scrollTo({top: y, behavior: 'smooth'});
        } 
        else {
            return null;
        }
    }

    return (
        <CTA 
            to={path?.includes('#') && !path.includes('http') ? '' : path}
            color={color}
            onClick={handleScroll}
            target={path?.charAt(0) === '/' ? '_self' : '_blank'}
        >
            {string}
        </CTA>
    );
};

export default Button;