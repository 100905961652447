import React, { useState, useEffect } from 'react';
import APPLICATION_CONSTANTS from './constants/strings';
import Hero from './shared/Hero';
import SimpleBanner from './shared/SimpleBanner';
import ImageText from './shared/ImageText';
import CTABanner from './shared/CTABanner';
import AccordionSection from './shared/AccordionSection';
import OpenPositions from './shared/OpenPositions';

const Careers = () => {

    const [openPositions, setOpenPositions] = useState([]);
    useEffect(() => {
      // get data from Paycor job scraper API
      fetch(`https://api.ohsdigital.io/v1/open_roles`)
        .then(response => response.json()) // parse JSON from request
        .then(resultData => {
          setOpenPositions(resultData);
        }) // set data for open positions
  }, []);

    return (
        <div>
            <Hero 
                mainHeader={APPLICATION_CONSTANTS.CAREERS_HERO.MAIN_HEADER}
                subHeader={APPLICATION_CONSTANTS.CAREERS_HERO.SUB_HEADER}
                src={'/images/oncoursehome_careers_site_graphic.png'}
                altText={'side profile of a smiling woman with a headset'}
                buttonString={APPLICATION_CONSTANTS.JOB_OPENINGS}
                hasButton
            />
            <SimpleBanner string={APPLICATION_CONSTANTS.CAREERS_SIMPLE_BANNER_STRING} />
            <ImageText
                src={'/images/careers-textimage.jpg'}
                altText={'a group of people talking together'}
                mainHeader={APPLICATION_CONSTANTS.CAREERS_IMAGETEXT1.MAIN_HEADER}
                subHeader1={APPLICATION_CONSTANTS.CAREERS_IMAGETEXT1.SUB_HEADER}
            />
            <AccordionSection 
                headerString={APPLICATION_CONSTANTS.CAREERS_ACCORDION_SECTION_HEADER} 
                accordionStrings={APPLICATION_CONSTANTS.CAREERS_ACCORDION_STRINGS} 
            />
            <ImageText
                src={'/images/about-textimage.jpg'}
                altText={'a group of people sitting together, listening and taking notes'}
                mainHeader={APPLICATION_CONSTANTS.CAREERS_IMAGETEXT2.MAIN_HEADER}
                subHeader1={APPLICATION_CONSTANTS.CAREERS_IMAGETEXT2.SUB_HEADER}
            />
            {openPositions.length && <OpenPositions
                headerString={APPLICATION_CONSTANTS.CAREERS_OPEN_POSITIONS.MAIN_HEADER}
                subHeaderString={APPLICATION_CONSTANTS.CAREERS_OPEN_POSITIONS.SUB_HEADER}
                openPositions={openPositions}
            />}
            <CTABanner strings={APPLICATION_CONSTANTS.CAREERS_CTABANNER_STRINGS} />
        </div>
    );
};

export default Careers;